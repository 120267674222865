import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material'
import { ArrowRight as ArrowRightIcon } from 'react-feather'

import { Table as TableComponent, TableRow, MUI } from 'components'

import { IncidentRowType, IncidentTableType } from './incidentTable.types'

import { formatters } from 'helpers'
import nonComplianceReportConstants from 'constants/nonComplianceReport'
import incidentManegementConstants from 'constants/incidentManegement'
import routes from 'constants/routes'
import theme from 'theme'

const TableRowIncidents = ({ incident }: IncidentRowType) => {
  const navigate = useNavigate()
  const statusId = incident?.nonComplianceReport?.statusId
  const statusInfo = nonComplianceReportConstants.STATUSES_ID[statusId]

  const navigateToEdit = (incidentId: number) => {
    navigate(
      reverse(routes.app.incidentManegements.edit, {
        incidentId: incidentId,
      })
    )
  }

  return (
    <TableRow onClick={() => navigateToEdit(incident?.id)}>
      <TableCell>{incident?.id}</TableCell>
      <TableCell>{incident?.companyName}</TableCell>
      <TableCell>
        {formatters.date.ptBrFormatDate(incident?.createdAt)}
      </TableCell>
      <TableCell>
        {
          incidentManegementConstants.LABELS[
            incident?.incidentManegementStep?.step
          ]
        }
      </TableCell>
      <TableCell align="center">
        {statusId ? (
          <MUI.Chip
            label={statusInfo?.label?.toUpperCase()}
            $color={statusInfo?.color}
            $background={statusInfo?.background}
            size="small"
            $width={theme.spacing(34)}
          />
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>{incident?.legalOpinion ? 'Sim' : 'Não'}</TableCell>
      <TableCell>{incident?.shouldNotify ? 'Sim' : 'Não'}</TableCell>
      <TableCell align="right">
        <Button
          variant="outlined"
          color="neutral"
          endIcon={<ArrowRightIcon size={20} />}
          onClick={() => navigateToEdit(incident?.id)}
        >
          DETALHES
        </Button>
      </TableCell>
    </TableRow>
  )
}

const IncidentsTable = ({ incidents }: IncidentTableType) => {
  return (
    <TableContainer>
      <TableComponent aria-label="listagem dos incidentes">
        <TableHead>
          <TableRow>
            <TableCell align="left" width="12.5%">
              Identificador
            </TableCell>
            <TableCell align="left" width="25%">
              Empresa
            </TableCell>
            <TableCell align="left" width="20%">
              Data de criação
            </TableCell>
            <TableCell align="left" width="17%">
              Etapa
            </TableCell>
            <TableCell align="center" width="20%">
              Tratamento
            </TableCell>
            <TableCell align="left" width="12%">
              Parecer
            </TableCell>
            <TableCell align="left" width="12%">
              Notificação
            </TableCell>
            <TableCell align="center" width="5%">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {incidents &&
            incidents?.map((incident) => (
              <TableRowIncidents incident={incident} key={incident.id} />
            ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  )
}

export default IncidentsTable
