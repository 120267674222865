import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Controller, useFormContext } from 'react-hook-form'

import { ConclusionLiaType } from './conclusionLia.types'

import { QuestionsDpoFormType } from '../../schema'

const ConclusionLia = ({ createdAt, show = false }: ConclusionLiaType) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<QuestionsDpoFormType>()

  return (
    <Paper component={Box} p={4}>
      <Typography fontWeight={700} color="primary">
        Conclusão
      </Typography>
      <Box mt={4}>
        <FormControl error={!!errors.questionDpo37}>
          <FormLabel id="demo-radio-buttons-group-label">
            É possível utilizar o Legítimo Interesse nesse tratamento de dados?
          </FormLabel>
          <Controller
            control={control}
            name="questionDpo37"
            render={({ field }) => (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                row
                {...field}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio disabled={show} />}
                  label="SIM"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio disabled={show} />}
                  label="NÃO"
                />
              </RadioGroup>
            )}
          />
          <FormHelperText>{errors?.questionDpo37?.message}</FormHelperText>
        </FormControl>
      </Box>
      <Box mt={2}>
        <Typography>Cometários adicionais</Typography>
        <Controller
          control={control}
          name="questionDpo38"
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              multiline
              minRows={3}
              disabled={show}
            />
          )}
        />
      </Box>
      <Box mt={2} display="flex" gap={2}>
        <Box>
          <Typography>Data</Typography>
          <Controller
            render={({ field }) => (
              <DatePicker
                format="dd/MM/yyyy"
                minDate={createdAt && new Date(Date.parse(createdAt))}
                {...field}
                slotProps={{
                  textField: {
                    helperText: errors?.questionDpo39?.message,
                    error: !!errors.questionDpo39,
                  },
                }}
                disabled={show}
              />
            )}
            control={control}
            name="questionDpo39"
          />
        </Box>
        <Box>
          <Typography>Local</Typography>
          <Controller
            control={control}
            name="questionDpo40"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.questionDpo40}
                helperText={errors?.questionDpo40?.message}
                disabled={show}
              />
            )}
          />
        </Box>
      </Box>
    </Paper>
  )
}

export default ConclusionLia
