import { Fragment } from 'react'
import {
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  Table as TableComponent,
  TableRow,
  Button,
} from '@mui/material'
import { reverse } from 'named-urls'
import { useNavigate } from 'react-router-dom'
import { ArrowRight as ArrowRightIcon } from 'react-feather'

import { Permitted } from 'components'
import { ChipStatus } from './components'

import { LegitimateInterestTableType } from './LegitimateInterestTabletypes'

import { DataProcessWithLiaType } from 'types/dataProcess.types'

import permissionTags from 'constants/permissionTags'
import date from 'helpers/formatters/date'
import constants from 'constants/index'

const LegitimateInterestTable = ({
  dataProcessesWithLia,
}: LegitimateInterestTableType) => {
  const { DISAPPROVED_LIA, PENDING_LIA, APPROVED, REVIEW_LIA_DPO } =
    constants.legitimateInterest.STATUSES_LIA

  const navigate = useNavigate()

  const handleClickDetails = (dataProcess: DataProcessWithLiaType) => {
    const statusId = dataProcess.statusId
    const id = dataProcess.id

    if ([DISAPPROVED_LIA, PENDING_LIA, APPROVED].includes(statusId)) {
      return navigate(
        reverse(constants.routes.app.legitimateInterest.show, {
          dataProcessId: id,
        })
      )
    }

    if (statusId === REVIEW_LIA_DPO) {
      return navigate(
        reverse(constants.routes.app.legitimateInterest.reviewDpo, {
          dataProcessId: id,
        })
      )
    }

    navigate(
      reverse(constants.routes.app.legitimateInterest.reviewClient, {
        dataProcessId: id,
      })
    )
  }

  return (
    <Fragment>
      <TableContainer>
        <TableComponent aria-label="Listagem processos com legítimo interesse">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome do processo</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Documento</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Status</TableCell>
              <Permitted
                someTags={[
                  permissionTags.LEGITIMATE_INTEREST.MANAGE,
                  permissionTags.LEGITIMATE_INTEREST.SHOW,
                ]}
              >
                <TableCell align="center">Ações</TableCell>
              </Permitted>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProcessesWithLia?.map((dataProcess) => (
              <TableRow key={dataProcess?.id}>
                <TableCell>{dataProcess?.id}</TableCell>
                <TableCell>{dataProcess?.name}</TableCell>
                <TableCell>{dataProcess?.company?.name}</TableCell>
                <TableCell>{dataProcess?.company?.document}</TableCell>
                <TableCell align="center">
                  {date.ptBrFormatDate(dataProcess?.updatedAt)}
                </TableCell>
                <TableCell align="center">
                  <ChipStatus statusId={dataProcess?.statusId} />
                </TableCell>
                <Permitted
                  someTags={[
                    permissionTags.LEGITIMATE_INTEREST.MANAGE,
                    permissionTags.LEGITIMATE_INTEREST.SHOW,
                  ]}
                >
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color="neutral"
                      endIcon={<ArrowRightIcon size={20} />}
                      onClick={() => handleClickDetails(dataProcess)}
                    >
                      DETALHES
                    </Button>
                  </TableCell>
                </Permitted>
              </TableRow>
            ))}
          </TableBody>
        </TableComponent>
      </TableContainer>
    </Fragment>
  )
}

export default LegitimateInterestTable
